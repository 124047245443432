/*
 * File: useSupaFetch.ts
 * Description: Reusable helper to fetch data from the "local" Nuxt API.
 *              This helper sends the supabase cookies over to the server.
 *              The server can then use the JWT cookie for authorisation.
 */

import type {UseFetchOptions} from 'nuxt/app';
import type {TSupabaseError, TValidationError} from "~/utils/errors";

/**
 * Result type of the server, which returns {data, errors}
 */
export interface ServerResponse<T> {
  data: T,
  errors: TValidationError[] | TSupabaseError[]
}

/**
 * Custom Fetch that passes the cookies over to the server so that the server can access the supabase JWT.
 * @param url
 * @param options
 */
export function useSupaFetch<T>(
  url: string | (() => string),
  options: UseFetchOptions<ServerResponse<T>> = {}
) {
  return useFetch(url, {
    ...{headers: useRequestHeaders(['cookie'])},
    ...options,
  });
}
